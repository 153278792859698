<template>
  <v-app>
    <v-main>
      <v-container fluid>
        {{ headerInfo }}

        <draggable
          v-model="photos"
          tag="div"
          @start="drag = true"
          @end="drag = false"
          @change="orderChanged"
          v-bind="dragOptions"
        >
          <transition-group
            tag="v-row"
            class="pt-2"
            :name="!drag ? 'photos-list' : null"
          >
            <v-col
              cols="12"
              sm="6"
              md="4"
              lg="4"
              v-for="photo in photos"
              :key="photo.large"
            >
              <v-card>
                <v-img
                  :src="photo.large"
                  :lazy-src="photo.small"
                  aspect-ratio="1"
                  contain
                  class="grey lighten-3"
                ></v-img>
                <v-card-actions>
                  <div>{{ photo.uploadedBy }}</div>
                  <v-spacer></v-spacer>
                  <v-btn icon large color="green" class="ma-1">
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                  <v-btn icon large color="red" class="ma-1">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </transition-group>
        </draggable>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import draggable from 'vuedraggable'

let userNumber = 1

function dummyPhoto() {
  const imgId = Math.floor(Math.random() * 1001)
  return {
    uploadedBy: `User ${userNumber++}`,
    large: `https://picsum.photos/id/${imgId}/300`,
    small: `https://picsum.photos/id/${imgId}/30`,
  }
}

export default {
  name: 'Demo',
  components: { draggable },
  props: {},
  data() {
    return {
      drag: false,
      dragOptions: {
        animation: 200,
        group: 'photos-list',
        disabled: false,
        ghostClass: 'ghost',
      },
      photos: [
        dummyPhoto(),
        dummyPhoto(),
        dummyPhoto(),
        dummyPhoto(),
      ],
    }
  },
  computed: {
    headerInfo() {
      if (process.env.NODE_ENV !== 'development') {
        return ''
      }
      return `Env: ${process.env.NODE_ENV}, Breakpoint: ${this.$vuetify.breakpoint.name}`
    },
  },
  methods: {
    orderChanged(state) {
      console.log(state)
      console.log(this.photos.map(e => e.uploadedBy))
    },
  },
}
</script>

<style>
.photos-list-move {
  transition: transform 1.5s;
}
</style>
